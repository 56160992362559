import flow from 'lodash/flow';

export class Colors {
  private static sanitizeNegative(value: number) {
    return value < 0 ? 0 : value;
  }

  private static sanitizeOver360(value: number) {
    return value > 360 ? 360 : value;
  }

  private static sanitizeOver255(value: number) {
    return value > 255 ? 255 : value;
  }

  private static sanitizeOver100(value: number) {
    return value > 100 ? 100 : value;
  }

  static sanitizeHue = flow(Colors.sanitizeNegative, Colors.sanitizeOver360);

  static sanitizeSaturation = flow(Colors.sanitizeNegative, Colors.sanitizeOver100);
  static sanitizeLightness = flow(Colors.sanitizeNegative, Colors.sanitizeOver100);
  static sanitizeAlpha = flow(Colors.sanitizeNegative, Colors.sanitizeOver100);
  static sanitizeRGB = flow(Colors.sanitizeNegative, Colors.sanitizeOver255);

  static isValidHexColor(value: string) {
    return /^#[\da-f]{6}$/i.test(value);
  }

  static isValidNumericInput(value: string) {
    return /^\d+$/.test(value);
  }
}
