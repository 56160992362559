import { Authentication } from '@Feature/Authentication/Page';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const AuthenticationRouter = () => {
  return (
    <Routes>
      <Route path={'/auth'} element={<Authentication />} />
    </Routes>
  );
};
