import { CanvasDto } from '@ApiType';
import { SupabaseClient } from '@DataSource/SupabaseClient';

export const getCanvasById = async (id: string): Promise<CanvasDto | null> => {
  const result = await SupabaseClient.from('canvas')
    .select('id, creator, created_at, updated_at, name, details')
    .eq('id', id);
  if (!result.data || result.data?.length === 0) {
    return null;
  }

  const canvas = result.data[0];
  return {
    id: canvas.id,
    creator: canvas.creator,
    name: canvas.name,
    createdAt: canvas.created_at,
    updatedAt: canvas.updated_at,
    details: canvas.details,
  };
};

export const updateCanvasName = async (id: string, name: string): Promise<void> => {
  await SupabaseClient.from('canvas').update({ name }).eq('id', id);
};

export const overwriteCanvasContent = async (id: string, content: string): Promise<void> => {
  await SupabaseClient.from('canvas')
    .update({ details: { content, shapeCount: 0 } })
    .eq('id', id);
};

type Shape = {
  geometry: object;
  material: object;
  object: object;
};
export const addShapeToCanvas = async (id: string, shape: Shape): Promise<void> => {
  await SupabaseClient.rpc('append_shape', {
    p_id: id,
    p_geometry: JSON.stringify(shape.geometry),
    p_material: JSON.stringify(shape.material),
    p_object: JSON.stringify(shape.object),
  });
};

export const updateCanvasPreviewFilePath = async (id: string, previewFilePath: string): Promise<void> => {
  await SupabaseClient.rpc('set_preview_file_path', { p_id: id, p_preview_file_path: previewFilePath });
};
