import React, { createRef, useState } from 'react';
import { CloseOutlined, HolderOutlined } from '@ant-design/icons';
import Draggable from 'react-draggable';

import style from './ColorPalette.module.css';

import { setActiveColor, setMode, toggleColorPalette } from '@Slice/canvas';
import { useDispatch, useSelector } from '@Hook/store';
import { IroColorPicker } from '@Feature/Canvas/Component/IroColorPicker';
import { ManualColorInput } from '@Feature/Canvas/Component/ManualColorInput';
import { Tooltip } from '@Component/Tooltip';
import { LightnessBand } from '@Feature/Canvas/Component/LightnessBand';
import { EyeDropperPreview } from '@Feature/Canvas/Component/EyeDropperPreview';
import { Color } from '@Entity/Color';

export enum ColorSpace {
  HSL,
  RGB,
  HEX,
}

type Props = {
  eyedropperPreviewPixelColors: string[][];
};

const ColorPalette: React.FC<Props> = ({ eyedropperPreviewPixelColors }) => {
  const [colorSpace, setColorSpace] = useState(ColorSpace.HSL);

  const dispatch = useDispatch();
  const containerRef = createRef<HTMLDivElement>();

  const showColorPalette = useSelector((state) => state.canvas.showColorPalette);
  const activeColor = useSelector<Color>((state) => Color.fromSerialized(state.canvas.activeColor));
  const activeMode = useSelector((state) => state.canvas.mode);
  const modeHistory = useSelector((state) => state.canvas.modeHistory);

  const reactivatePreviousMode = (mode = modeHistory[1] || modeHistory[0], addToHistory = true) => {
    if (activeMode === 'eye-drop' && mode) {
      dispatch(setMode({ mode, addToHistory }));
    }
  };

  if (!showColorPalette) {
    return <></>;
  }

  return (
    <Draggable nodeRef={containerRef} handle={'#moveHandler'}>
      <div className={style.container} ref={containerRef} onClick={() => reactivatePreviousMode()}>
        <div className={style.controls}>
          <HolderOutlined
            id={'moveHandler'}
            style={{
              fontSize: 14,
              fontWeight: 'bold',
              cursor: 'move',
              display: 'flex',
              width: '100%',
              height: '100%',
              color: 'white',
            }}
          />
          <Tooltip title={'Hide'}>
            <CloseOutlined style={{ fontSize: 12, color: 'white' }} onClick={() => dispatch(toggleColorPalette())} />
          </Tooltip>
        </div>
        {activeMode === 'eye-drop' && (
          <EyeDropperPreview eyedropperPreviewPixelColors={eyedropperPreviewPixelColors} colorSpace={colorSpace} />
        )}
        <IroColorPicker
          color={activeColor}
          onChange={(color) => {
            dispatch(setActiveColor(color.serialize()));
          }}
        />
        <LightnessBand color={activeColor} />
        <ManualColorInput
          color={activeColor}
          onChange={(color) => {
            dispatch(setActiveColor(color.serialize()));
          }}
          colorSpace={colorSpace}
          setColorSpace={setColorSpace}
        />
      </div>
    </Draggable>
  );
};

export { ColorPalette };
