import style from './LightnessBand.module.css';
import { ColorSwatch } from './component/ColorSwatch';
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
};

const LightnessBand = ({ color }: Props) => {
  const iroColor = Color.from(color);
  const alpha = iroColor.alpha;
  const lightnessValues = generateLightnessValues(iroColor);

  return (
    <div className={style.container}>
      {lightnessValues.map((color, index) => (
        <ColorSwatch key={index} color={color} active={index === 4} alpha={alpha} />
      ))}
    </div>
  );
};

const generateLightnessValues = (color: Color): Color[] => {
  color.alpha = 1;
  return [
    incrementLightness(color, 4),
    incrementLightness(color, 3),
    incrementLightness(color, 2),
    incrementLightness(color),
    color,
    decrementLightness(color),
    decrementLightness(color, 2),
    decrementLightness(color, 3),
    decrementLightness(color, 4),
  ];
};

const incrementLightness = (color: Color, steps = 1): Color => {
  const result = color.clone();
  result.setChannel('hsl', 'l', color.hsl.l - steps * 3);
  return result;
};

const decrementLightness = (color: Color, steps = 1): Color => {
  const result = color.clone();
  result.setChannel('hsl', 'l', color.hsl.l + steps * 3);
  return result;
};

export { LightnessBand };
