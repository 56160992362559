import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import style from './FullScreenLoader.module.css';

export const FullScreenLoader = () => {
  return (
    <div className={style.container}>
      <LoadingOutlined style={{ color: '#096dd9', fontSize: 40 }} />
    </div>
  );
};
