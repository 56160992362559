import { SupabaseClient } from '@DataSource/SupabaseClient';
import { CanvasDto } from '@ApiType';

export const getPortfolioItems = async (): Promise<CanvasDto[]> => {
  try {
    const result = await SupabaseClient.from('canvas')
      .select('id, created_at, creator, updated_at, name, details->>previewFilePath, details->>shapeCount')
      .is('deleted_at', null)
      .order('updated_at', { ascending: false });

    return (
      result.data?.map((item) => {
        return {
          id: item.id,
          creator: item.creator,
          createdAt: new Date(`${item.created_at}Z`),
          updatedAt: new Date(`${item.updated_at}Z`),
          name: item.name,
          details: {
            previewFilePath: item.previewFilePath,
            content: null,
            shapeCount: item.shapeCount ? parseInt(item.shapeCount) : 0,
          },
        };
      }) || []
    );
  } catch (error) {
    throw new Error('Api error', error as Error);
  }
};

export const deleteCanvas = async (canvasId: string): Promise<void> => {
  try {
    await SupabaseClient.rpc('soft_delete_canvas', { p_id: canvasId });
  } catch (error) {
    throw new Error('Api error', error as Error);
  }
};

export const createCanvas = async (): Promise<string> => {
  try {
    const user = await SupabaseClient.auth.getUser();
    const result = await SupabaseClient.from('canvas').insert({ creator: user.data.user?.id }).select('id');
    return result.data?.[0].id;
  } catch (error) {
    throw new Error('Api error', error as Error);
  }
};
