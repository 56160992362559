import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Col, Popconfirm } from 'antd';
import { formatDuration, intervalToDuration } from 'date-fns';
import { CanvasDto } from '@ApiType';
import style from './PortfolioItem.module.css';

const getFormattedTimestamp = (startDate: Date, endDate: Date) => {
  const duration = intervalToDuration({
    start: startDate,
    end: endDate,
  });

  return formatDuration(duration);
};

const getFileHumanReadableTimestamp = (date: Date) => {
  return `Edited ${getFormattedTimestamp(date, new Date())} ago`;
};

type ArtworkProps = {
  canvas: CanvasDto;
  deleteCanvas: (canvasId: string) => Promise<void>;
};
export const PortfolioItem = ({ canvas, deleteCanvas }: ArtworkProps) => {
  return (
    <Col xs={12} sm={11} md={8} lg={6} xl={4} className={style.container}>
      <div className={style.artworkDetails}>
        <Link to={`/canvas/${canvas.id}`}>
          <div className={style.previewContainer}>
            {canvas.details.previewUrl && <img alt={`Preview for ${canvas.name}`} src={canvas.details.previewUrl} />}
          </div>
        </Link>
        <div className={style.metadata}>
          <span className={style.title}>{canvas.name || 'Untitled'}</span>
          <span className={style.timestamp}>{getFileHumanReadableTimestamp(canvas.updatedAt)}</span>
        </div>
        <Popconfirm
          title="Delete canvas"
          description={`You are about to delete ${canvas.name || 'Untitled'}.`}
          icon={<DeleteOutlined style={{ color: 'red' }} />}
          okText="Delete canvas"
          onConfirm={async () => {
            await deleteCanvas(canvas.id);
          }}
        >
          <DeleteOutlined className={style.deleteButton} />
        </Popconfirm>
      </div>
    </Col>
  );
};
