import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Mode } from '@Type/canvas';
import { Color, SerializedHslColor } from '@Entity/Color';
import { isMobile, isTablet } from '@Utility/Device';

export type DrawingState = {
  mode: Mode;
  modeHistory: Mode[];
  showColorPalette: boolean;
  activeColor: SerializedHslColor;
  showOnboarding: boolean;
};

const initialState: DrawingState = {
  mode: 'lasso',
  modeHistory: ['lasso'],
  showColorPalette: isTablet() || !isMobile(),
  activeColor: Color.from('#858585').serialize(),
  showOnboarding: false,
};

export const canvasSlice = createSlice({
  name: 'canvas',
  initialState,
  reducers: {
    setMode: (state, action: PayloadAction<{ mode: Mode; addToHistory: boolean }>) => {
      if (state.mode === action.payload.mode) {
        return;
      }

      state.mode = action.payload.mode;
      if (action.payload.addToHistory) {
        state.modeHistory.unshift(action.payload.mode);
      }
    },
    setActiveColor: (state, action: PayloadAction<SerializedHslColor>) => {
      state.activeColor = action.payload;
    },
    toggleColorPalette: (state) => {
      state.showColorPalette = !state.showColorPalette;
    },
    setColorPalette: (state, action: PayloadAction<boolean>) => {
      state.showColorPalette = action.payload;
    },
    setIsOnboardingVisible: (state, action: PayloadAction<boolean>) => {
      state.showOnboarding = action.payload;
    },
  },
});

export const { setMode, setActiveColor, toggleColorPalette, setColorPalette, setIsOnboardingVisible } =
  canvasSlice.actions;

export default canvasSlice.reducer;
