import { useDispatch, useSelector } from '@Hook/store';
import { setMode } from '@Slice/canvas';
import { useCallback, useEffect, useState } from 'react';
import { LongPressEventType, useLongPress } from 'use-long-press';

export function useEyeDropInputControls(isActivelyDrawing: boolean) {
  const dispatch = useDispatch();
  const [isZooming, setIsZooming] = useState(false);

  const modeHistory = useSelector((state) => state.canvas.modeHistory);
  const currentMode = useSelector((state) => state.canvas.mode);

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    // check isActivelyDrawing?

    if (event.code === 'KeyI') {
      dispatch(setMode({ mode: 'eye-drop', addToHistory: true }));
    }

    if (event.code === 'KeyZ') {
      setIsZooming(true);
    }

    if (event.code === 'AltLeft' && !isZooming) {
      dispatch(setMode({ mode: 'eye-drop', addToHistory: false }));
    }
  };

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    if (event.code === 'AltLeft' && !isZooming) {
      dispatch(setMode({ mode: modeHistory[0], addToHistory: false }));
    }

    if (event.code === 'KeyZ') {
      setIsZooming(false);
    }
  };

  const handlePointerDown = (event: PointerEvent) => {
    if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
      if (event.button === 2) {
        dispatch(setMode({ mode: 'eye-drop', addToHistory: false }));
      }
    }
  };

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      if (event.pointerType === 'mouse' || event.pointerType === 'pen') {
        if (event.button === 2) {
          dispatch(setMode({ mode: modeHistory[0], addToHistory: false }));
        }
      }
    },
    [modeHistory]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    document.addEventListener('pointerdown', handlePointerDown);
    document.addEventListener('pointerup', handlePointerUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modeHistory, isZooming, setIsZooming]);

  const bindLongPress = useLongPress(
    () => {
      if (!isActivelyDrawing && currentMode === 'lasso') {
        dispatch(setMode({ mode: 'eye-drop', addToHistory: false }));
      }
    },
    {
      threshold: 1000,
      detect: LongPressEventType.Touch,
      onFinish: () => {
        console.log('finish long press', isActivelyDrawing);
        dispatch(setMode({ mode: modeHistory[0], addToHistory: false }));
      },
    }
  );

  return { bindLongPress };
}
