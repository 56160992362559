import { CanvasDto } from '@ApiType';
import { FullScreenLoader } from '@Component/FullScreenLoader';
import React, { useEffect, useState } from 'react';
import { getCanvasById } from '@Feature/Canvas/Api/CanvasRepository';
import { Draw } from '../Component/Draw';
import { Navigate, useParams } from 'react-router-dom';

export function CanvasPage() {
  const [canvas, setCanvas] = useState<CanvasDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const params = useParams();
  useEffect(() => {
    if (params.canvasId) {
      getCanvasById(params.canvasId)
        .then((response) => setCanvas(response))
        .finally(() => setIsLoading(false));
    }
  }, [params.canvasId]);

  if (!params.canvasId) {
    return <Navigate to="/404" />;
  }

  if (isLoading) {
    return <FullScreenLoader />;
  }

  if (!canvas) {
    return <Navigate to="/404" />;
  }

  return <Draw canvas={canvas} />;
}
