import { CanvasDto } from '@ApiType';
import { useShapeDiscardControls } from '@Feature/Canvas/Hook/useShapeDiscardControls';
import { useDispatch, useSelector } from '@Hook/store';
import { exportBase60ImageDataToJpegFile } from '@Utility/Base64ImageDataJpegExporter/base64ImageDataJpegExporter';
import { Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import style from './Canvas.module.css';

import { DevInfo } from '@Feature/Canvas/Component/DevInfo';
import { CanvasHelper } from '@Feature/Canvas/Helper/CanvasHelper';
import { usePanInputControls } from '@Feature/Canvas/Hook/usePanInputControls';
import { useEyeDropInputControls } from '@Feature/Canvas/Hook/useEyeDropInputControls';
import { useLassoInputControls } from '@Feature/Canvas/Hook/useLassoInputControls';
import { useZoomInputControls } from '@Feature/Canvas/Hook/useZoomInputControls';
import { Color } from '@Entity/Color';
import { useColorPaletteShortcuts } from '@Feature/Canvas/Hook/useColorPaletteShortcuts';
import { setActiveColor, setMode } from '@Slice/canvas';
import { preventLoupe } from '@Feature/Canvas/Hook/preventLoupe';

type CanvasProps = {
  canvas: CanvasDto;
  setEyedropperPreviewPixelColors: (state: string[][]) => void;
  isExporting: boolean;
  setIsExporting: (state: boolean) => void;
};
const Canvas: React.FC<CanvasProps> = ({ canvas, setEyedropperPreviewPixelColors, isExporting, setIsExporting }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const canvasState = useSelector((state) => state.canvas);
  const canvasHelper = useRef<CanvasHelper | null>(null);
  const [isActivelyDrawing, setIsActivelyDrawing] = React.useState(false);
  const [isActivelyPanning, setIsActivelyPanning] = React.useState(false);
  const dispatch = useDispatch();

  useEffect(initializeCanvasHelper, []);
  const { bindDrag } = usePanInputControls(isActivelyDrawing);
  useLassoInputControls();
  const { bindLongPress } = useEyeDropInputControls(isActivelyDrawing);
  const { bindPinch } = useZoomInputControls(
    () => canvasHelper.current?.zoomIn(),
    () => canvasHelper.current?.zoomOut(),
    () => canvasHelper.current?.resetZoom(),
    isActivelyDrawing
  );
  useColorPaletteShortcuts();
  useShapeDiscardControls(() => canvasHelper.current?.discardShape());
  preventLoupe();

  useEffect(() => {
    canvasHelper.current?.setActiveMode(canvasState.mode);
    const pointerDownHandler = () => {
      if (canvasState.mode === 'pan') {
        setIsActivelyPanning(true);
      }
    };
    const pointerUpHandler = (event: PointerEvent) => {
      getColorWithEyedropper(event);
      setIsActivelyPanning(false);
    };
    const pointerMoveHandler = (event: PointerEvent) => updateEyedropperPreviewRegionAfterPointerEvent(event);
    canvasHelper.current?.renderer.domElement.addEventListener('pointerdown', pointerDownHandler, true);
    canvasHelper.current?.renderer.domElement.addEventListener('pointerup', pointerUpHandler, true);
    canvasHelper.current?.renderer.domElement.addEventListener('pointermove', pointerMoveHandler, true);

    console.log('change mode in Canvas');
    return () => {
      canvasHelper.current?.renderer.domElement.removeEventListener('pointerdown', pointerDownHandler, true);
      canvasHelper.current?.renderer.domElement.removeEventListener('pointerup', pointerUpHandler, true);
      canvasHelper.current?.renderer.domElement.removeEventListener('pointermove', pointerMoveHandler, true);
    };
  }, [canvasState.mode]);

  useEffect(() => {
    canvasHelper.current?.setActiveColor(Color.fromSerialized(canvasState.activeColor));
  }, [canvasState.activeColor]);

  function initializeCanvasHelper() {
    if (!containerRef.current) {
      return;
    }
    canvasHelper.current = new CanvasHelper(
      containerRef.current,
      canvas.id,
      canvas.creator,
      canvas.details.content,
      canvas.details.shapeCount,
      setIsActivelyDrawing
    );
  }

  useEffect(() => {
    initializeEyedropperPreview();
  }, []);

  function getStyleBasedOnCanvasActiveMode() {
    if (canvasState.mode === 'lasso') {
      return style.canvasLasso;
    }

    if (canvasState.mode === 'eye-drop') {
      return style.canvasEyedropper;
    }

    if (canvasState.mode === 'pan') {
      return isActivelyPanning ? style.canvasPanGrabbing : style.canvasPanGrab;
    }

    if (canvasState.mode === 'zoom-in') {
      return style.canvasZoomIn;
    }

    if (canvasState.mode === 'zoom-out') {
      return style.canvasZoomOut;
    }
  }

  const getColorWithEyedropper = (event: PointerEvent) => {
    if (canvasState.mode === 'eye-drop' && canvasHelper.current) {
      updateEyedropperPreviewRegionAfterPointerEvent(event);
      const x = event.clientX;
      const y = event.clientY;
      const nextActiveColor = canvasHelper.current?.getPixelColor(x, y);
      if (nextActiveColor) {
        dispatch(setActiveColor(nextActiveColor));
      }
    }
  };

  const initializeEyedropperPreview = () => {
    if (canvasHelper.current) {
      const defaultPixelColorArray = canvasHelper.current?.getDefaultPixelColors();
      setEyedropperPreviewPixelColors(defaultPixelColorArray);
    }
  };

  const updateEyedropperPreviewRegionAfterPointerEvent = (event: PointerEvent) => {
    setTimeout(() => getEyedropperPreviewRegion(event.clientX, event.clientY), 0);
  };

  const getEyedropperPreviewRegion = (x: number, y: number) => {
    if (canvasHelper.current) {
      const pixelColors = canvasHelper.current?.getPixelColorRegion(x, y);
      setEyedropperPreviewPixelColors(pixelColors);
    }
  };

  const exportJpg = () => {
    const imageData = canvasHelper.current?.getBase64SerializedScene();
    if (imageData) {
      exportBase60ImageDataToJpegFile(imageData);
    }
  };

  return (
    <div {...bindLongPress()}>
      <div
        className={`${style.container} ${getStyleBasedOnCanvasActiveMode()}`}
        ref={containerRef}
        {...bindDrag()}
        {...bindPinch()}
      />
      {isExporting && (
        <>
          <div className={style.exportTopOutline} />
          <div className={style.exportRightOutline} />
          <div className={style.exportBottomOutline} />
          <div className={style.exportLeftOutline} />
        </>
      )}
      {isExporting && (
        <div className={style.exportControls}>
          <Button type={'primary'} onClick={exportJpg} style={{ marginRight: 16 }}>
            Export
          </Button>
          <Button
            onClick={() => {
              setIsExporting(false);
              dispatch(setMode({ mode: canvasState.modeHistory[0], addToHistory: false }));
            }}
          >
            Cancel
          </Button>
        </div>
      )}
      <DevInfo />
    </div>
  );
};

export { Canvas };
