import React, { useEffect, useState } from 'react';

import style from './HexColorInput.module.css';
import { Input } from 'antd';
import { Colors } from '@Utility/Colors';
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

const HexColorInput: React.FC<Props> = ({ color, onChange }) => {
  const [localColor, setLocalColor] = useState(Color.from(color));
  const [alpha, setAlpha] = React.useState<string>((localColor.alpha * 100).toString());
  const [hex, setHex] = React.useState<string>(localColor.hexString.replace('#', ''));

  function updateInputs(color: Color) {
    setHex(color.hexString.replace('#', ''));
    setAlpha((color.alpha * 100).toString());
  }

  useEffect(() => {
    setLocalColor(Color.from(color));
    updateInputs(Color.from(color));
  }, [color.hslaString]);

  function handleHexChange(event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const parsedValue = `#${event.target.value}`;
    if (!Colors.isValidHexColor(parsedValue)) {
      setHex(localColor.hexString.replace('#', ''));
      return;
    }
    localColor.hexString = parsedValue;
    onChange(localColor);
  }

  function handleAlphaChange(event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputValue = event.target.value;
    if (!Colors.isValidNumericInput(inputValue)) {
      setAlpha((localColor.alpha * 100).toString());
      return;
    }

    const numericValue = parseInt(inputValue);
    const sanitizedValue = Colors.sanitizeAlpha(numericValue);

    if (sanitizedValue !== numericValue) {
      updateInputs(localColor);
    }

    localColor.alpha = sanitizedValue / 100;
    onChange(localColor);
  }

  return (
    <div className={style.container}>
      <Input
        className={style.inputHex}
        size={'small'}
        value={hex}
        onChange={(event) => setHex(event.target.value)}
        onBlur={handleHexChange}
        onPressEnter={handleHexChange}
        prefix={'#'}
      />
      <Input
        className={style.inputExtended}
        size={'small'}
        value={alpha}
        onChange={(event) => setAlpha(event.target.value)}
        onBlur={handleAlphaChange}
        onPressEnter={handleAlphaChange}
        suffix={<span>%</span>}
      />
    </div>
  );
};

export { HexColorInput };
