import { useDispatch, useSelector } from '@Hook/store';
import { setMode } from '@Slice/canvas';
import { isPrimaryModifierKey } from '@Utility/KeyboardEvent/keyboardEvent';
import { useCallback, useEffect } from 'react';
import { useGesture } from '@use-gesture/react';
import { Mode } from '@Type/canvas';

export function useZoomInputControls(
  zoomInCallback: () => void,
  zoomOutCallback: () => void,
  resetZoomCallback: () => void,
  isActivelyDrawing: boolean
) {
  const dispatch = useDispatch();
  const modeHistory = useSelector((state) => state.canvas.modeHistory);

  const activateZoomAndPanModeMomentarily = () => dispatch(setMode({ mode: 'pan', addToHistory: false }));
  const revertToPreviouslyActiveMode = (mode: Mode) => dispatch(setMode({ mode, addToHistory: false }));

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    event.preventDefault();

    if (event.code === 'KeyZ' && !isPrimaryModifierKey(event)) {
      dispatch(setMode({ mode: event.altKey ? 'zoom-out' : 'zoom-in', addToHistory: false }));
    }
    if (event.code === 'Equal' && isPrimaryModifierKey(event)) {
      zoomInCallback();
    }
    if (event.code === 'Minus' && isPrimaryModifierKey(event)) {
      zoomOutCallback();
    }
    if (event.code === 'Digit0' && isPrimaryModifierKey(event)) {
      resetZoomCallback();
    }
  }, []);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement) {
        return;
      }
      if (event.code === 'KeyZ') {
        dispatch(setMode({ mode: modeHistory[0], addToHistory: false }));
      }
    },

    [modeHistory]
  );

  const bindPinch = useGesture({
    onPinchStart: () => {
      if (!isActivelyDrawing) {
        activateZoomAndPanModeMomentarily();
      }
    },
    onPinchEnd: () => {
      if (!isActivelyDrawing) {
        revertToPreviouslyActiveMode(modeHistory[0]);
      }
    },
  });

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [modeHistory]);

  return { bindPinch };
}
