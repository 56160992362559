import React from 'react';
import { useSelector } from '@Hook/store';
import { isProduction } from '@Utility/Device';
import style from './DevInfo.module.css';

const DevInfo: React.FC = () => {
  const activeMode = useSelector((state) => state.canvas.mode);

  if (isProduction()) {
    return <></>;
  }

  return (
    <div className={style.container}>
      <span className={style.mode}>Canvas mode: {activeMode}</span>
    </div>
  );
};

export { DevInfo };
