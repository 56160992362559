import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';

import './index.css';
import { ConfigProvider, ThemeConfig, theme as antTheme } from 'antd';
import { isProduction } from '@Utility/Device';

if (isProduction()) {
  Sentry.init({
    dsn: 'https://da0a691cbc6542f39f3d2bcad252a8b4@o4505176108630016.ingest.sentry.io/4505176110071808',
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const theme: ThemeConfig = {
  algorithm: antTheme.darkAlgorithm,
  token: {
    borderRadiusXS: 2,
    borderRadiusSM: 2,
    borderRadius: 2,
    borderRadiusLG: 2,
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider theme={theme}>
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
