import React from 'react';
import { createCanvas } from '@Feature/Portfolio/Api/PortfolioRepository';
import { useNavigate } from 'react-router-dom';

import style from './Dashboard.module.css';

import { Overview } from './component/Overview';
import { NewArtworkButton } from './component/NewArtworkButton';
export function Dashboard() {
  const navigate = useNavigate();

  const createFileAndOpen = async () => {
    const canvasId = await createCanvas();
    navigate(`/canvas/${canvasId}`);
  };

  return (
    <div className={style.container}>
      <NewArtworkButton createFileAndOpen={createFileAndOpen} />
      <Overview />
      <div style={{ padding: '30px' }}></div>
    </div>
  );
}
