export class OnboardingHelper {
  public static completeOnboarding(): void {
    localStorage.setItem('onboarding', '1');
  }

  public static needsOnboarding(): boolean {
    const serialized = localStorage.getItem('onboarding');
    return serialized ? parseInt(serialized) !== 1 : true;
  }
}
