import { Navigate } from 'react-router-dom';
import { Dashboard } from '../../Portfolio/Component/Dashboard';
import { Toolbar } from '../../Portfolio/Component/Toolbar';
import React from 'react';

export const PortfolioPage = () => {
  const serializedSession = localStorage.getItem('session');

  if (!serializedSession) {
    return <Navigate to="/auth" />;
  }

  const session = JSON.parse(serializedSession);

  if (!session) {
    return <Navigate to="/auth" />;
  }

  return (
    <>
      <Toolbar />
      <Dashboard />
    </>
  );
};
