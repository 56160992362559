import React, { createRef } from 'react';
import Icon, { QuestionOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import style from './Toolbar.module.css';

import { useDispatch, useSelector } from '@Hook/store';
import { setMode, toggleColorPalette } from '@Slice/canvas';
import { ReactComponent as Hand } from '@Icon/hand.svg';
import { ReactComponent as Eyedropper } from '@Icon/eyedropper.svg';
import { ReactComponent as Lasso } from '@Icon/lasso.svg';
import { Mode } from '@Type/canvas';
import { Tooltip } from '@Component/Tooltip';
import { DownOutlined, MenuOutlined } from '@ant-design/icons';
import { Color } from '@Entity/Color';
import { CanvasName } from '@Feature/Canvas/Component/Toolbar/component/FileName';
import { useOnboarding } from '@Feature/Canvas/Hook/useOnboarding';
import { createCanvas } from '@Feature/Portfolio/Api/PortfolioRepository';

type ToolbarProps = {
  canvasId: string;
  canvasName: string | null;
  startExportExperience: () => void;
};

const Toolbar: React.FC<ToolbarProps> = ({ canvasId, canvasName, startExportExperience }) => {
  const activeMode = useSelector((state) => state.canvas.mode);
  const activeColor = useSelector<Color>((state) => Color.fromSerialized(state.canvas.activeColor));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const containerRef = createRef<HTMLDivElement>();
  const { setIsVisible } = useOnboarding();

  const createFileAndOpenInNewTab = async () => {
    const canvasId = await createCanvas();
    window.open(`/canvas/${canvasId}`);
  };

  const getActiveClasses = (mode: Mode) => {
    if (mode === activeMode) {
      return `${style.toolIcon} ${style.toolIcon__active}`;
    }
    return style.toolIcon;
  };

  const toggleMode = (mode: Mode, addToHistory = true) => {
    dispatch(setMode({ mode, addToHistory }));
  };

  const items: MenuProps['items'] = [
    {
      key: 'back-to-files',
      label: <div onClick={() => navigate('/portfolio')}>Back to portfolio</div>,
    },
    {
      key: 'new-file',
      label: <div onClick={createFileAndOpenInNewTab}>New canvas</div>,
    },
    {
      key: 'export',
      label: (
        <div onClick={startExportExperience}>
          Export canvas<span>(⌘ + E)</span>
        </div>
      ),
    },
  ];

  return (
    <div className={style.container} ref={containerRef}>
      <span className={style.tools}>
        <Dropdown menu={{ items }} trigger={['click']} placement="bottom">
          <a onClick={(e) => e.preventDefault()} className={style.dropDown}>
            <Space>
              <MenuOutlined />
              <DownOutlined style={{ fontSize: '9px' }} />
            </Space>
          </a>
        </Dropdown>

        <Tooltip title="Lasso (L)" placement="bottom">
          <Icon component={Lasso} className={getActiveClasses('lasso')} onClick={() => toggleMode('lasso')} />
        </Tooltip>

        <Tooltip title="Eyedropper (I)" placement="bottom">
          <Icon
            component={Eyedropper}
            className={getActiveClasses('eye-drop')}
            onClick={() => toggleMode('eye-drop')}
          />
        </Tooltip>

        <Tooltip title="Hand (H)" placement="bottom">
          <Icon component={Hand} className={getActiveClasses('pan')} onClick={() => toggleMode('pan')} />
        </Tooltip>

        <Tooltip title="Palette" placement="bottom">
          <div
            className="toolIcon"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '40px',
              height: '40px',
            }}
          >
            <div
              className={style.activeColor}
              onClick={() => dispatch(toggleColorPalette())}
              style={{
                backgroundColor: activeColor.hslaString,
                border: '2px solid rgba(255,255,255,1)',
                borderRadius: '50%',
                height: '14px',
                width: '14px',
                margin: '0',
              }}
            />
          </div>
        </Tooltip>
      </span>
      <CanvasName canvasId={canvasId} canvasName={canvasName} />
      <span className={style.indicator}>
        <Tooltip title="Help" placement="bottom">
          <QuestionOutlined className={style.toolIcon} onClick={() => setIsVisible(true)} />
        </Tooltip>
      </span>
    </div>
  );
};

export { Toolbar };
