export const preventLoupe = () => {
  function createDoubleTapPreventer(timeout_ms: number) {
    let doubleTapTimer = setTimeout(() => {
      return;
    }, 0);
    let doubleTapPressed = false;
    const canvas = document.querySelector('canvas');

    return function (event: TouchEvent) {
      if (event.target !== canvas) {
        return;
      }

      clearTimeout(doubleTapTimer);
      if (doubleTapPressed) {
        event.preventDefault();
        doubleTapPressed = false;
      } else {
        doubleTapPressed = true;
        doubleTapTimer = setTimeout(() => {
          doubleTapPressed = false;
        }, timeout_ms);
      }
    };
  }

  document.body.addEventListener('touchstart', createDoubleTapPreventer(500), { passive: false });
};
