import Icon from '@ant-design/icons';
import { SupabaseClient } from '@DataSource/SupabaseClient';
import { message } from 'antd';
import { getUnixTime } from 'date-fns';
import { ReactComponent as DiscordIcon } from '@Icon/discord.svg';

import React, { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './Authentication.module.css';

const getButtonLabel = (isLoading: boolean, isComplete: boolean) => {
  if (isLoading) {
    return 'Loading';
  }

  if (isComplete) {
    return 'Try again in one minute';
  }

  return 'Send magic link';
};

export const Authentication = () => {
  const [loading, setLoading] = useState(false);
  const [attemptComplete, setAttemptComplete] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const navigate = useNavigate();

  const handleLoginWithMagicLink = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);
    const { error } = await SupabaseClient.auth.signInWithOtp({
      email: emailAddress,
      options: {
        emailRedirectTo: `${window.location.origin}/`,
      },
    });
    if (error) {
      message.error('Something went wrong. Please try again later or get in touch.');
    } else {
      message.info(`Email sent to ${emailAddress}. Check you inbox and click the link.`);
    }

    setLoading(false);
    setAttemptComplete(true);

    setTimeout(() => setAttemptComplete(false), 1000 * 60);
  };

  const handleLoginWithDiscord = async () => {
    const { error } = await SupabaseClient.auth.signInWithOAuth({
      provider: 'discord',
      options: {
        redirectTo: `${window.location.origin}/`,
      },
    });

    if (error) {
      message.error('Something went wrong. Please try again later or get in touch.');
    }
  };

  useEffect(() => {
    const serializedSession = localStorage.getItem('session');
    if (!serializedSession) {
      return;
    }

    const session = JSON.parse(serializedSession);
    if (!session) {
      return;
    }

    if (session.expires_at > getUnixTime(new Date())) {
      navigate('/portfolio');
    }
  }, []);

  return (
    <div className={style.container}>
      <h1 className={style.header}>willow</h1>
      <div className={style.formContainer}>
        <button disabled={loading || attemptComplete} className={style.buttonDiscord} onClick={handleLoginWithDiscord}>
          <Icon component={DiscordIcon} style={{ fontSize: 20, marginRight: 4 }} />
          Continue with Discord
        </button>
        <span className={style.separator}>or</span>
        <form className={style.form} onSubmit={handleLoginWithMagicLink}>
          <input
            type="email"
            className={style.input}
            placeholder="Email"
            value={emailAddress}
            required={true}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
          <button disabled={loading || attemptComplete} className={style.button}>
            {getButtonLabel(loading, attemptComplete)}
          </button>
        </form>
      </div>
    </div>
  );
};
