import React, { useEffect, useState } from 'react';

import style from './RgbColorInput.module.css';
import { Input } from 'antd';
import { Colors } from '@Utility/Colors';
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

enum Channel {
  r = 'r',
  g = 'g',
  b = 'b',
  a = 'a',
}

const RgbColorInput: React.FC<Props> = ({ color, onChange }) => {
  const [localColor, setLocalColor] = useState(Color.from(color));
  const [red, setRed] = useState<string>(localColor.rgb.r.toString());
  const [green, setGreen] = useState<string>(localColor.rgb.g.toString());
  const [blue, setBlue] = useState<string>(localColor.rgb.b.toString());
  const [alpha, setAlpha] = useState<string>((localColor.alpha * 100).toString());

  function updateInputs(color: Color) {
    setRed(color.rgb.r.toString());
    setGreen(color.rgb.g.toString());
    setBlue(color.rgb.b.toString());
    setAlpha((color.alpha * 100).toString());
  }

  useEffect(() => {
    setLocalColor(Color.from(color));
    updateInputs(Color.from(color));
  }, [color.hslaString]);

  function handleChannelChange(
    event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    channel: Channel,
    sanitizer: (value: number) => number
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputValue = event.target.value;
    if (!Colors.isValidNumericInput(inputValue)) {
      updateInputs(localColor);
      return;
    }

    const numericValue = parseInt(inputValue);
    const sanitizedValue = sanitizer(numericValue);

    if (sanitizedValue !== numericValue) {
      updateInputs(localColor);
    }

    if (channel === Channel.a) {
      localColor.alpha = sanitizedValue / 100;
    } else {
      localColor.setChannel('rgba', channel, sanitizedValue);
    }

    onChange(localColor);
  }

  return (
    <div className={style.container}>
      <Input
        className={style.input}
        size={'small'}
        value={red}
        onChange={(event) => setRed(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.r, Colors.sanitizeRGB)}
        onPressEnter={(event) => handleChannelChange(event, Channel.r, Colors.sanitizeRGB)}
      />
      <Input
        className={style.input}
        size={'small'}
        value={green}
        onChange={(event) => setGreen(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.g, Colors.sanitizeRGB)}
        onPressEnter={(event) => handleChannelChange(event, Channel.g, Colors.sanitizeRGB)}
      />
      <Input
        className={style.input}
        size={'small'}
        value={blue}
        onChange={(event) => setBlue(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.b, Colors.sanitizeRGB)}
        onPressEnter={(event) => handleChannelChange(event, Channel.b, Colors.sanitizeRGB)}
      />
      <Input
        className={style.inputExtended}
        size={'small'}
        value={alpha}
        onChange={(event) => setAlpha(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.a, Colors.sanitizeAlpha)}
        onPressEnter={(event) => handleChannelChange(event, Channel.a, Colors.sanitizeAlpha)}
        suffix={<span>%</span>}
      />
    </div>
  );
};

export { RgbColorInput };
