import { useDispatch } from '@Hook/store';
import { toggleColorPalette } from '@Slice/canvas';
import { useCallback, useEffect } from 'react';

export function useColorPaletteShortcuts() {
  const dispatch = useDispatch();

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    if (event.code === 'KeyP' && event.shiftKey) {
      dispatch(toggleColorPalette());
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
