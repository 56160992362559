import { SupabaseClient } from '@DataSource/SupabaseClient';
import { decode } from 'base64-arraybuffer';

export const uploadCanvasPreview = async (filePath: string, base64EncodedPreview: string): Promise<string | null> => {
  try {
    const base64 = base64EncodedPreview.split('base64,')[1];
    const { data } = await SupabaseClient.storage
      .from('canvas-previews')
      .upload(filePath, decode(base64), { upsert: true, contentType: 'image/jpeg' });
    return data?.path || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getPreviewUrlBasedOnFilePath = async (filePath: string): Promise<string | null> => {
  try {
    const { data } = await SupabaseClient.storage.from('canvas-previews').createSignedUrl(filePath, 5 * 60, {
      transform: {
        height: 100,
        quality: 20,
      },
    });
    return data?.signedUrl || null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
