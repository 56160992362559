import { useExportShortcut } from '@Feature/Canvas/Hook/useExportShortcut';
import { usePrimaryModifierKeyControls } from '@Feature/Canvas/Hook/usePrimaryModifierKeyControls';
import { notification } from 'antd';
import React, { useEffect, useState } from 'react';
import style from './Draw.module.css';
import { Canvas } from '@Feature/Canvas/Component/Canvas';
import { Toolbar } from '@Feature/Canvas/Component/Toolbar';
import { ColorPalette } from '@Feature/Canvas/Component/ColorPalette';
import { useDispatch, useSelector } from '@Hook/store';
import { setColorPalette, setMode } from '@Slice/canvas';
import { CanvasDto } from '@ApiType';
import { Onboarding } from '@Feature/Canvas/Component/Onboarding';

const Draw: React.FC<{ canvas: CanvasDto }> = ({ canvas }) => {
  const dispatch = useDispatch();
  const mode = useSelector((state) => state.canvas.mode);
  const modeHistory = useSelector((state) => state.canvas.modeHistory);
  const [eyedropperPreviewPixelColors, setEyedropperPreviewPixelColors] = useState<string[][]>([]);
  const [isExporting, setIsExporting] = React.useState(false);

  const startExportExperience = () => {
    setIsExporting(true);
    dispatch(setMode({ mode: 'pan', addToHistory: false }));
    dispatch(setColorPalette(false));
    if (!localStorage.getItem('export_onboarding')) {
      notification.config({ top: 65, maxCount: 1 });
      notification.info({
        message: 'Export image',
        description:
          'Use zoom and pan to frame your canvas for exporting. When you are ready, click the Export button above',
        placement: 'topRight',
        duration: 3,
      });
      localStorage.setItem('export_onboarding', '1');
    }
  };

  const cancelExportExperience = () => {
    setIsExporting(false);
    dispatch(setMode({ mode: modeHistory[0], addToHistory: false }));
    dispatch(setColorPalette(true));
  };

  const resetModeOnPrimaryModifierKey = () => {
    dispatch(setMode({ mode: 'lasso', addToHistory: false }));
  };

  useExportShortcut(startExportExperience, cancelExportExperience);
  usePrimaryModifierKeyControls(resetModeOnPrimaryModifierKey);

  useEffect(() => {
    if (mode === 'eye-drop') {
      dispatch(setColorPalette(true));
    }
  }, [mode]);

  return (
    <div className={style.container}>
      <Toolbar canvasId={canvas.id} canvasName={canvas.name} startExportExperience={startExportExperience} />
      <Canvas
        canvas={canvas}
        setEyedropperPreviewPixelColors={setEyedropperPreviewPixelColors}
        isExporting={isExporting}
        setIsExporting={setIsExporting}
      />
      <ColorPalette eyedropperPreviewPixelColors={eyedropperPreviewPixelColors} />
      <Onboarding />
    </div>
  );
};

export { Draw };
