import { FullScreenLoader } from '@Component/FullScreenLoader';
import { useAuthSession } from '@Hook/useAuthSession';
import React from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as amplitude from '@amplitude/analytics-browser';

import { NotFoundRouter } from '@Feature/404';
import { AuthenticationRouter } from '@Feature/Authentication';
import { CanvasRouter } from '@Feature/Canvas';
import { PortfolioRouter } from '@Feature/Portfolio';
import { store } from '@Store/store';
import style from './App.module.css';

const App = () => {
  amplitude.init('d3de0f0a5cfc808dfbfc30d2c409c8f5', undefined, {
    defaultTracking: { sessions: true, pageViews: true, formInteractions: true, fileDownloads: true },
  });

  const { isFetchingSession } = useAuthSession();

  if (isFetchingSession) {
    return <FullScreenLoader />;
  }
  return (
    <Provider store={store}>
      <div className={style.root}>
        <Router>
          <Routes>
            <Route path={'/'} index element={<Navigate to={'/portfolio'} />} />
          </Routes>
          <CanvasRouter />
          <PortfolioRouter />
          <AuthenticationRouter />
          <NotFoundRouter />
        </Router>
      </div>
    </Provider>
  );
};

export default App;
