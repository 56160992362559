import { ProtectedPage } from '@Component/ProtectedPage';
import { CanvasPage } from './Page';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const CanvasRouter = () => {
  return (
    <Routes>
      <Route
        path={'/canvas/:canvasId'}
        action={({ params }) => params.canvasId}
        element={
          <ProtectedPage>
            <CanvasPage />
          </ProtectedPage>
        }
      />
      <Route
        path={'/file/:canvasId'}
        action={({ params }) => params.canvasId}
        element={
          <ProtectedPage>
            <CanvasPage />
          </ProtectedPage>
        }
      />
    </Routes>
  );
};
