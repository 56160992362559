/* (c) Copyright Frontify Ltd., all rights reserved. */

import React, { useRef } from 'react';
import style from './Onboarding.module.css';
import { Button, Collapse, Modal } from 'antd';
import { useOnboarding } from '@Feature/Canvas/Hook/useOnboarding';
import { OnboardingHelper } from '@Feature/Canvas/Helper/OnboardingHelper';

const Onboarding: React.FC = () => {
  const { isVisible, setIsVisible } = useOnboarding();
  const videoRef = useRef(null);

  const hideModal = () => {
    setIsVisible(false);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    videoRef.current?.contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
  };

  return (
    <Modal
      open={isVisible}
      onCancel={hideModal}
      centered={true}
      width={1000}
      footer={[
        <Button key="back" onClick={hideModal}>
          Close
        </Button>,
      ]}
    >
      <h1>Willow - Help</h1>
      <Collapse defaultActiveKey={OnboardingHelper.needsOnboarding() ? 'loom' : ''}>
        <Collapse.Panel key={'loom'} header={'Watch demo'}>
          <iframe
            ref={videoRef}
            src="https://www.youtube-nocookie.com/embed/G2RLsfRKgVI?modestbranding=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;autoplay=1&amp;start=0&amp;vq=hd1080&enablejsapi=1"
            title="Willow intro video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
            className={style.loom}
          ></iframe>
        </Collapse.Panel>
      </Collapse>

      <h2>Shortcuts</h2>
      <div className={style.container}>
        <div className={style.column}>
          <h3>Tools</h3>
          <div>
            Lasso <code className={style.code}>L</code>
          </div>
          <div>
            Eyedropper <code className={style.code}>I</code>
          </div>
          <div>
            Pan <code className={style.code}>H</code>
          </div>
          <h3>Quick keys</h3>
          <div>
            Toggle Eyedropper
            <div className={style.combo}>
              <code className={style.code}>ALT/OPT</code>
              {' OR '}
              <code className={style.code}>RMB</code>
            </div>
          </div>
          <div>
            Toggle Pan{' '}
            <div className={style.combo}>
              <code className={style.code}>Space</code>
              {' OR '}
              <code className={style.code}>MMB</code>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <h3>Zooming</h3>
          <div>
            Zoom in{' '}
            <div className={style.combo}>
              <code className={style.code}>Z</code>
              {' + '}
              <code className={style.code}>LMB</code>
            </div>
          </div>
          <div>
            Zoom out{' '}
            <div className={style.combo}>
              <code className={style.code}>Z</code>
              {' + '}
              <code className={style.code}>ALT/OPT</code>
              {' + '}
              <code className={style.code}>LMB</code>
            </div>
          </div>
          <div>
            Zoom in{' '}
            <div className={style.combo}>
              <code className={`${style.code} ${style.character}`}>⌘</code>
              {' + '}
              <code className={style.code}>+</code>
            </div>
          </div>
          <div>
            Zoom out{' '}
            <div className={style.combo}>
              <code className={`${style.code} ${style.character}`}>⌘</code>
              {' + '}
              <code className={style.code}>-</code>
            </div>
          </div>
          <div>
            Zoom to 100%{' '}
            <div className={style.combo}>
              <code className={`${style.code} ${style.character}`}>⌘</code>
              {' + '}
              <code className={style.code}>0</code>
            </div>
          </div>
        </div>
        <div className={style.column}>
          <h3>Other useful shortcuts</h3>
          <div>
            Export canvas{' '}
            <div className={style.combo}>
              <code className={`${style.code} ${style.character}`}>⌘</code>
              {' + '}
              <code className={style.code}>E</code>
            </div>
          </div>
          <div>
            Show/Hide Palette{' '}
            <div className={style.combo}>
              <code className={`${style.code} ${style.character}`}>⇧</code>
              {' + '}
              <code className={style.code}>P</code>
            </div>
          </div>
          <div>
            Quit process <code className={style.code}>ESC</code>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export { Onboarding };
