import { useDispatch } from '@Hook/store';
import { setMode } from '@Slice/canvas';
import { useCallback, useEffect } from 'react';

export function useLassoInputControls() {
  const dispatch = useDispatch();

  const activateLassoMode = () => dispatch(setMode({ mode: 'lasso', addToHistory: true }));

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    if (event.code === 'KeyL' || event.code === 'Escape') {
      activateLassoMode();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
