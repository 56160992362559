import { Mode } from '@Type/canvas';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const DEFAULT_ZOOM_LEVEL = 1;
const ZOOM_STEP = 1.5;

class InputDeviceOverride {
  camera: THREE.OrthographicCamera;
  controls: OrbitControls;
  activeMode: Mode = 'lasso';

  pointerHandlers: Map<string, () => void>;
  mouseLocation = { x: 0, y: 0 };

  constructor(camera: THREE.OrthographicCamera, renderer: THREE.WebGLRenderer, activeMode: Mode) {
    this.camera = camera;
    this.controls = this.initializeControls(renderer);

    this.pointerHandlers = new Map();
    this.activeMode = activeMode;
  }

  private initializeControls(renderer: THREE.WebGLRenderer) {
    const controls = new OrbitControls(this.camera, renderer.domElement);

    controls.mouseButtons = {
      MIDDLE: THREE.MOUSE.PAN,
    };

    controls.enableRotate = false;
    controls.enableZoom = true;
    controls.enablePan = true;
    controls.zoomToCursor = true;

    return controls;
  }

  public setMode(mode: Mode) {
    this.activeMode = mode;

    if (this.activeMode === 'pan') {
      this.activatePan();
    } else {
      this.deactivatePan();
    }
  }

  public doOverride(): void {
    this.initializeHandlers();
    this.initializeListeners();
  }

  public enableZoomAndPan = () => {
    this.controls.enablePan = true;
    this.controls.enableZoom = true;
  };

  public disableZoomAndPan = () => {
    this.controls.enablePan = false;
    this.controls.enableZoom = false;
  };

  private activatePan = () => {
    this.controls.mouseButtons.LEFT = THREE.MOUSE.PAN;
    this.controls.touches.ONE = THREE.TOUCH.PAN;
    this.controls.update();
  };

  private deactivatePan = () => {
    this.controls.mouseButtons.LEFT = THREE.MOUSE.ROTATE;
    this.controls.touches.ONE = undefined;
    this.controls.update();
  };

  private updateMouseLocation = (xLocation: number, yLocation: number) => {
    this.mouseLocation.x = xLocation;
    this.mouseLocation.y = yLocation;
  };

  private initializeHandlers = () => {
    this.pointerHandlers.set('zoom-in', this.handleZoomIn.bind(this));
    this.pointerHandlers.set('zoom-out', this.handleZoomOut.bind(this));
  };

  public handleZoomIn() {
    this.camera.zoom *= ZOOM_STEP;
    this.repositionViewOnZoomIn();
    this.camera.updateProjectionMatrix();
  }

  public handleZoomOut() {
    this.repositionViewOnZoomOut();
    this.camera.zoom /= ZOOM_STEP;
    this.camera.updateProjectionMatrix();
  }

  public handleResetZoom() {
    this.camera.zoom = DEFAULT_ZOOM_LEVEL;
    this.camera.updateProjectionMatrix();
  }

  private repositionViewOnZoomIn() {
    const currentX = this.camera.position.x;
    const xOffset = (this.mouseLocation.x - window.innerWidth / 2) * (1 / this.camera.zoom);
    const adjustedX = currentX + xOffset;
    const currentY = this.camera.position.y;
    const yOffset = (window.innerHeight / 2 - this.mouseLocation.y) * (1 / this.camera.zoom);
    const adjustedY = currentY + yOffset;

    this.camera.position.set(adjustedX, adjustedY, this.camera.position.z);
    this.camera.lookAt(adjustedX, adjustedY, 0);
    this.controls.target.set(adjustedX, adjustedY, 0);
  }

  private repositionViewOnZoomOut() {
    const currentX = this.camera.position.x;
    const xOffset = (window.innerWidth / 2 - this.mouseLocation.x) * (1 / this.camera.zoom);
    const adjustedX = currentX + xOffset;
    const currentY = this.camera.position.y;
    const yOffset = (this.mouseLocation.y - window.innerHeight / 2) * (1 / this.camera.zoom);
    const adjustedY = currentY + yOffset;

    this.camera.position.set(adjustedX, adjustedY, this.camera.position.z);
    this.camera.lookAt(adjustedX, adjustedY, 0);
    this.controls.target.set(adjustedX, adjustedY, 0);
  }

  private initializeListeners = (): void => {
    window.addEventListener('pointerdown', this.handlePointerDown);
    window.addEventListener('pointermove', this.handlePointerMove);
  };

  private handlePointerDown = () => {
    this.pointerHandlers.get(this.activeMode)?.();
  };

  private handlePointerMove = (event: PointerEvent) => {
    this.updateMouseLocation(event.clientX, event.clientY);
  };
}

export { InputDeviceOverride };
