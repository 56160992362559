export function isMobile() {
  return (
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent) ||
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  );
}

export function isTablet() {
  return (
    /ipad/i.test(navigator.userAgent) ||
    /(tablet|ipad|playbook|silk)|(android(?!.*mobile))/i.test(navigator.userAgent) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
  );
}

export function isProduction() {
  return process.env.NODE_ENV === 'production';
}

export function isWindowsDevice() {
  return window.navigator.userAgent.includes('Windows');
}

export function isMac() {
  return window.navigator.userAgent.includes('Macintosh');
}

export function isIPadOSorIOSDevice() {
  return window.navigator.userAgent.includes('iPad') || window.navigator.userAgent.includes('iPhone');
}

export function isAndroidDevice() {
  return window.navigator.userAgent.includes('Android');
}

export function isLinuxDevice() {
  return window.navigator.userAgent.includes('Linux');
}
