import React from 'react';
import { Tooltip as AntTooltip, TooltipProps } from 'antd';
import { isMobile } from '@Utility/Device';

const Tooltip = ({ title, placement, children }: TooltipProps) => {
  if (isMobile()) {
    return <>{children}</>;
  }
  return (
    <AntTooltip title={title} placement={placement || 'right'} trigger={isMobile() ? 'click' : 'hover'}>
      {children}
    </AntTooltip>
  );
};

export { Tooltip };
