import { useDispatch, useSelector } from '@Hook/store';
import { setMode } from '@Slice/canvas';
import { Mode } from '@Type/canvas';
import { useCallback, useEffect, useState } from 'react';
import { useGesture } from '@use-gesture/react';

export function usePanInputControls(isActivelyDrawing: boolean) {
  const dispatch = useDispatch();

  const modeHistory = useSelector((state) => state.canvas.modeHistory);
  const [isPanning, setIsPanning] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const activatePanMode = () => dispatch(setMode({ mode: 'pan', addToHistory: true }));
  const activatePanModeMomentarily = () => dispatch(setMode({ mode: 'pan', addToHistory: false }));
  const revertToPreviouslyActiveMode = (mode: Mode) => dispatch(setMode({ mode, addToHistory: false }));

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    if (event.code === 'KeyH') {
      activatePanMode();
      setIsPanning(true);
    }

    if (event.code === 'Space') {
      activatePanModeMomentarily();
      setIsPanning(true);
    }
  }, []);

  const handleKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.target instanceof HTMLInputElement) {
        return;
      }
      if (event.code === 'Space') {
        revertToPreviouslyActiveMode(modeHistory[0]);
        setIsPanning(false);
      }
    },
    [modeHistory]
  );

  const handleMouseDown = (event: PointerEvent) => {
    if (event.pointerType === 'mouse') {
      if (event.button === 1) {
        // activatePanModeMomentarily();
        setIsPanning(true);
      }
    }
  };

  const handlePointerDown = useCallback((event: PointerEvent) => {
    handleMouseDown(event);
    // TODO: handle pen & touch
  }, []);

  const handleMouseUp = (event: PointerEvent) => {
    if (event.pointerType === 'mouse') {
      if (event.button === 1) {
        // revertToPreviouslyActiveMode(modeHistory[0]);
        setIsPanning(false);
      }
    }
  };

  const handlePointerUp = useCallback(
    (event: PointerEvent) => {
      if (isPanning) {
        handleMouseUp(event);
      }
    },
    [modeHistory]
  );

  const bindDrag = useGesture(
    {
      onDragStart: ({ touches }) => {
        if (!isActivelyDrawing && touches === 2) {
          activatePanModeMomentarily();
          setIsDragging(true);
        }
      },
      onDragEnd: () => {
        if (isPanning && isDragging) {
          revertToPreviouslyActiveMode(modeHistory[0]);
        }
      },
    },
    {
      drag: {
        pointer: {
          touch: true,
        },
      },
    }
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('pointerdown', handlePointerDown);
    document.addEventListener('pointerup', handlePointerUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('pointerdown', handlePointerDown);
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [modeHistory]);

  return { bindDrag };
}
