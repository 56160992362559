import React, { useEffect, useState } from 'react';
import style from './HslColorInput.module.css';
import { Input } from 'antd';
import { Colors } from '@Utility/Colors';
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

enum Channel {
  h = 'h',
  s = 's',
  l = 'l',
  a = 'a',
}

const HslColorInput: React.FC<Props> = ({ color, onChange }) => {
  const [localColor, setLocalColor] = useState(Color.from(color));

  const [hue, setHue] = useState<string>(localColor.hsl.h.toString());
  const [saturation, setSaturation] = useState<string>(localColor.hsl.s.toString());
  const [lightness, setLightness] = useState<string>(localColor.hsl.l.toString());
  const [alpha, setAlpha] = useState((localColor.alpha * 100).toString());

  function updateInputs(color: Color) {
    setHue(color.hsl.h.toString());
    setSaturation(color.hsl.s.toString());
    setLightness(color.hsl.l.toString());
    setAlpha((color.alpha * 100).toString());
  }

  useEffect(() => {
    setLocalColor(Color.from(color));
    updateInputs(Color.from(color));
  }, [color.hslaString]);

  function handleChannelChange(
    event: React.FocusEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>,
    channel: Channel,
    sanitizer: (value: number) => number
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const inputValue = event.target.value;
    if (!Colors.isValidNumericInput(inputValue)) {
      updateInputs(localColor);
      return;
    }

    const numericValue = parseInt(inputValue);
    const sanitizedValue = sanitizer(numericValue);

    if (sanitizedValue !== numericValue) {
      updateInputs(localColor);
    }

    if (channel === Channel.a) {
      localColor.alpha = sanitizedValue / 100;
    } else {
      localColor.setChannel('hsla', channel, sanitizedValue);
    }

    onChange(localColor);
  }

  return (
    <div className={style.container} id={'hslInput'}>
      <Input
        className={style.input}
        size={'small'}
        value={hue}
        onChange={(event) => setHue(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.h, Colors.sanitizeHue)}
        onPressEnter={(event) => handleChannelChange(event, Channel.h, Colors.sanitizeHue)}
      />
      <Input
        className={style.input}
        size={'small'}
        value={saturation}
        onChange={(event) => setSaturation(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.s, Colors.sanitizeSaturation)}
        onPressEnter={(event) => handleChannelChange(event, Channel.s, Colors.sanitizeSaturation)}
      />
      <Input
        className={style.input}
        size={'small'}
        value={lightness}
        onChange={(event) => setLightness(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.l, Colors.sanitizeLightness)}
        onPressEnter={(event) => handleChannelChange(event, Channel.l, Colors.sanitizeLightness)}
      />
      <Input
        className={style.inputExtended}
        size={'small'}
        value={alpha}
        onChange={(event) => setAlpha(event.target.value)}
        onBlur={(event) => handleChannelChange(event, Channel.a, Colors.sanitizeAlpha)}
        onPressEnter={(event) => handleChannelChange(event, Channel.a, Colors.sanitizeAlpha)}
        suffix={<span>%</span>}
      />
    </div>
  );
};

export { HslColorInput };
