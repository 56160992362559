import React from 'react';

import style from './ManualColorInput.module.css';
import { HslColorInput } from './component/HslColorInput';
import { RgbColorInput } from './component/RgbColorInput';
import { HexColorInput } from './component/HexColorInput';
import { Select } from 'antd';
import { Color } from '@Entity/Color';
import { ColorSpace } from '@Feature/Canvas/Component/ColorPalette';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
  colorSpace: ColorSpace;
  setColorSpace: (colorSpace: ColorSpace) => void;
};

const { Option } = Select;

const ManualColorInput: React.FC<Props> = ({ color, onChange, colorSpace, setColorSpace }) => {
  return (
    <div className={style.container}>
      <Select
        bordered={false}
        style={{ padding: 0 }}
        defaultValue={ColorSpace.HSL}
        onChange={(value) => {
          setColorSpace(value);
        }}
        size={'small'}
        dropdownMatchSelectWidth={false}
      >
        <Option value={ColorSpace.HSL}>HSL</Option>
        <Option value={ColorSpace.RGB}>RGB</Option>
        <Option value={ColorSpace.HEX}>HEX</Option>
      </Select>
      {colorSpace === ColorSpace.HSL && <HslColorInput color={color} onChange={onChange} />}
      {colorSpace === ColorSpace.RGB && <RgbColorInput color={color} onChange={onChange} />}
      {colorSpace === ColorSpace.HEX && <HexColorInput color={color} onChange={onChange} />}
    </div>
  );
};

export { ManualColorInput };
