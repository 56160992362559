import React from 'react';

import style from './NewArtworkButton.module.css';

import { Button, Col, Row } from 'antd';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { ReactComponent as Lasso } from '@Icon/lasso.svg';

interface Props {
  createFileAndOpen: () => void;
}

export const NewArtworkButton = ({ createFileAndOpen }: Props) => {
  return (
    <Row>
      <Col span={12} className={style.container}>
        <Button className={style.button} type="default" size="large" onClick={createFileAndOpen}>
          <Icon component={Lasso} style={{ fontSize: 18, color: '#40A9FF' }} />
          <span className={style.buttonLabel}>New canvas</span>
          <PlusOutlined style={{ color: '#8C8C8C' }} />
        </Button>
      </Col>
    </Row>
  );
};
