import { ProtectedPage } from '@Component/ProtectedPage';
import { PortfolioPage } from '../Portfolio/Page';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

export const PortfolioRouter = () => {
  return (
    <Routes>
      <Route
        path={'/portfolio'}
        element={
          <ProtectedPage>
            <PortfolioPage />
          </ProtectedPage>
        }
      />
      <Route path={'/files'} element={<Navigate to={'/portfolio'} replace={true} />} />
    </Routes>
  );
};
