import { LoadingOutlined } from '@ant-design/icons';
import React from 'react';
import style from './FullHeightLoader.module.css';

export const FullHeightLoader = () => {
  return (
    <div className={style.container}>
      <LoadingOutlined style={{ color: '#096dd9', fontSize: 40 }} />
    </div>
  );
};
