import { LogoutButton } from '@Component/LogoutButton';
import React from 'react';

import style from './Toolbar.module.css';

const Toolbar: React.FC = () => {
  const session = JSON.parse(localStorage.getItem('session') || '{}');

  return (
    <div className={style.container}>
      <span>Willow</span>
      <span className={style.logOutContainer}>
        <span className={style.userEmail}>{session.user.email}</span>
        <LogoutButton />
      </span>
    </div>
  );
};

export { Toolbar };
