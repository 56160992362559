import React from 'react';
import { Navigate } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ProtectedPage: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const serializedSession = localStorage.getItem('session');

  if (!serializedSession) {
    return <Navigate to="/auth" />;
  }

  const session = JSON.parse(serializedSession);

  if (!session) {
    return <Navigate to="/auth" />;
  }

  return children;
};
