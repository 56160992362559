import { SupabaseClient } from '@DataSource/SupabaseClient';
import { getUnixTime } from 'date-fns';
import { useEffect, useState } from 'react';

export const useAuthSession = () => {
  const [isFetchingSession, setIsFetchingSession] = useState(true);

  const listenForChangesInAuthenticationState = () => {
    const { data } = SupabaseClient.auth.onAuthStateChange((event, session) => {
      localStorage.setItem('session', JSON.stringify(session));
      setIsFetchingSession(false);
    });

    return () => {
      data.subscription.unsubscribe();
    };
  };

  useEffect(listenForChangesInAuthenticationState, []);

  const refreshSessionIfExistingSessionHasExpired = () => {
    const serializedSession = localStorage.getItem('session');
    if (!serializedSession) {
      return;
    }

    const session = JSON.parse(serializedSession);
    if (!session) {
      return;
    }

    if (session.expires_at > getUnixTime(new Date())) {
      return;
    }

    setIsFetchingSession(true);
    SupabaseClient.auth
      .refreshSession({ refresh_token: session.refresh_token })
      .then((response) => {
        localStorage.setItem('session', JSON.stringify(response.data.session));
        setIsFetchingSession(false);
      })
      .then((error) => console.error(error));
  };

  useEffect(refreshSessionIfExistingSessionHasExpired, []);

  return { isFetchingSession };
};
