import iro from '@jaames/iro';
import { IroColorValue } from '@irojs/iro-core/dist/color';

type ColorValue = IroColorValue;
export type SerializedHslColor = {
  h: number;
  s: number;
  l: number;
  a?: number;
};

class Color extends iro.Color {
  private constructor(colorValue: ColorValue) {
    super(colorValue);
  }

  static from(colorValue: ColorValue) {
    return new Color(colorValue);
  }

  static fromSerialized(serializedHslColor: SerializedHslColor) {
    return new Color(serializedHslColor);
  }

  serialize(): SerializedHslColor {
    return this.hsla;
  }

  clone(): Color {
    return new Color(this);
  }
}

export { Color };
