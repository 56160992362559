import { isAndroidDevice, isIPadOSorIOSDevice, isLinuxDevice, isMac, isWindowsDevice } from '@Utility/Device';

export const isPrimaryModifierKey = (event: KeyboardEvent): boolean => {
  return (
    (isIPadOSorIOSDevice() && event.ctrlKey) ||
    (isWindowsDevice() && event.ctrlKey) ||
    (isAndroidDevice() && event.ctrlKey) ||
    (isLinuxDevice() && event.ctrlKey) ||
    (isMac() && event.metaKey)
  );
};
