import React, { MutableRefObject, createRef, useEffect, useRef } from 'react';
import iro from '@jaames/iro';
import ColorPicker = iro.ColorPicker;
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
  onChange: (color: Color) => void;
};

const LayoutConfig = {
  width: 243,
  layout: [
    {
      component: iro.ui.Box,
      options: {},
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'hue',
      },
    },
    {
      component: iro.ui.Slider,
      options: {
        sliderType: 'alpha',
      },
    },
  ],
};

const IroColorPicker: React.FC<Props> = ({ color, onChange }) => {
  const divRef = createRef<HTMLDivElement>();
  const colorPicker: MutableRefObject<ColorPicker | null> = useRef(null);

  useEffect(() => {
    if (!colorPicker.current) {
      colorPicker.current = iro.ColorPicker(divRef.current as HTMLDivElement, {
        color,
        ...LayoutConfig,
      });
    }

    colorPicker.current?.on('color:change', (selectedColor: Color) => {
      onChange(Color.from(selectedColor.hsla));
    });
  }, []);

  function isColorSetFromOutside() {
    return colorPicker.current?.color.hslaString !== color.hslaString;
  }

  useEffect(() => {
    if (isColorSetFromOutside()) {
      colorPicker.current?.color.set(color);
    }
  }, [color.hslaString]);

  return <div ref={divRef} />;
};

export { IroColorPicker };
