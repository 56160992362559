import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NotFound } from '@Feature/404/Page';

export const NotFoundRouter = () => {
  return (
    <Routes>
      <Route path={'/404'} element={<NotFound />} />
    </Routes>
  );
};
