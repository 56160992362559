import React from 'react';
import style from './EyeDropperPreview.module.css';
import { Color } from '@Entity/Color';
import { ColorSpace } from '../ColorPalette';

type Props = {
  eyedropperPreviewPixelColors: string[][];
  colorSpace: ColorSpace;
};

const EyeDropperPreview: React.FC<Props> = ({ eyedropperPreviewPixelColors, colorSpace }) => {
  const midPoint = Math.floor(eyedropperPreviewPixelColors.length ** 2 / 2);
  const previewMatrixSize = eyedropperPreviewPixelColors.length;
  const activeColor = Color.from(
    eyedropperPreviewPixelColors[Math.floor(previewMatrixSize / 2)][Math.floor(previewMatrixSize / 2)]
  );

  const getColorLabel = (color: Color, colorSpace: ColorSpace): string => {
    switch (colorSpace) {
      case ColorSpace.HSL:
        return color.hslaString;
      case ColorSpace.RGB:
        return color.rgbaString;
      case ColorSpace.HEX:
        return color.hex8String;
      default:
        return '';
    }
  };

  return (
    <div className={style.container}>
      <div className={style.previewContainer}>
        {eyedropperPreviewPixelColors.map((row: string[], rowKey: number) => (
          <div key={rowKey} className={style.row}>
            {row.map((pixel, pixelKey: number) => (
              <div
                key={pixelKey}
                className={
                  rowKey * eyedropperPreviewPixelColors.length + pixelKey === midPoint
                    ? style.pixelMidpoint
                    : style.pixel
                }
                style={{
                  backgroundColor: pixel,
                }}
              />
            ))}
          </div>
        ))}
      </div>
      <span className={style.previewColor}>{getColorLabel(activeColor, colorSpace)}</span>
    </div>
  );
};

export { EyeDropperPreview };
