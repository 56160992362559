import style from '@Feature/Portfolio/Component/Toolbar/Toolbar.module.css';
import React from 'react';

import Icon from '@ant-design/icons';
import { SupabaseClient } from '@DataSource/SupabaseClient';
import { ReactComponent as LogOut } from '@Icon/logout.svg';

import { message } from 'antd';
import { useNavigate } from 'react-router-dom';

export const LogoutButton = () => {
  const navigate = useNavigate();

  return (
    <Icon
      component={LogOut}
      className={style.logOutIconButton}
      onClick={() => {
        SupabaseClient.auth.signOut().then((response) => {
          if (response.error) {
            message.error('Something went wrong');
          }

          localStorage.removeItem('session');
          navigate('/auth');
        });
      }}
    />
  );
};
