import { format } from 'date-fns';

export function exportBase60ImageDataToJpegFile(base64ImageData: string): void {
  const link = document.createElement('a');
  link.href = base64ImageData;
  link.download = getFilename();
  link.click();
}

function getFilename() {
  const now = new Date();
  return `willow-painting ${format(now, 'yyyy-MM-dd HH-mm-ss')}.jpg`;
}
