import { isPrimaryModifierKey } from '@Utility/KeyboardEvent/keyboardEvent';
import { useCallback, useEffect } from 'react';

export function usePrimaryModifierKeyControls(handlePrimaryModifierKeyPress: () => void) {
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (isPrimaryModifierKey(event)) {
      handlePrimaryModifierKeyPress();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
