import React, { useEffect, useState } from 'react';
import { deleteCanvas, getPortfolioItems } from '@Feature/Portfolio/Api/PortfolioRepository';
import { getPreviewUrlBasedOnFilePath } from '@Feature/Canvas/Api/FileRepository';
import { CanvasDto, FetchState } from '@ApiType';

import { FullHeightLoader } from '@Component/FullHeightLoader';
import style from './Overview.module.css';
import { Row } from 'antd';
import { PortfolioItem } from './component/PortfolioItem';

export function Overview() {
  const [fetchState, setFetchState] = useState<FetchState>('loading');
  const [portfolioItems, setPortfolioItems] = useState<CanvasDto[]>([]);

  useEffect(() => {
    const fetchPortfolioItems = async () => {
      const portfolioItems = await getPortfolioItems();
      setPortfolioItems(portfolioItems);
      setFetchState('success');
      return portfolioItems;
    };

    const fetchPreviewUrls = async (portfolioItems: CanvasDto[]) => {
      const portfolioItemsWithPreviewUrl: CanvasDto[] = [];
      Promise.all(
        portfolioItems.map((item) => {
          return item.details.previewFilePath ? getPreviewUrlBasedOnFilePath(item.details.previewFilePath) : null;
        })
      ).then((values) => {
        for (const portfolioItem of portfolioItems) {
          const previewUrl = values.find((value) => value?.includes(portfolioItem.id)) || undefined;
          portfolioItemsWithPreviewUrl.push({ ...portfolioItem, details: { ...portfolioItem.details, previewUrl } });
        }
        setPortfolioItems(portfolioItemsWithPreviewUrl);
      });
    };

    const fetchPortfolioItemsWithPreviewUrls = async () => {
      const portfolioItems = await fetchPortfolioItems();
      await fetchPreviewUrls(portfolioItems);
    };

    fetchPortfolioItemsWithPreviewUrls().catch((error) => {
      console.log(error);
      setFetchState('error');
    });
  }, []);

  const deleteCanvasAndUpdateLocalState = async (canvasId: string) => {
    await deleteCanvas(canvasId);
    const nonDeletedPortfolioItems = portfolioItems.filter((item) => item.id !== canvasId);
    setPortfolioItems(nonDeletedPortfolioItems);
  };

  if (fetchState === 'loading') {
    return <FullHeightLoader />;
  }

  if (fetchState === 'error') {
    return <span>error</span>;
  }

  return (
    <Row wrap={true} className={style.container}>
      {portfolioItems.map((canvas) => (
        <PortfolioItem canvas={canvas} key={canvas.id} deleteCanvas={deleteCanvasAndUpdateLocalState} />
      ))}
    </Row>
  );
}
