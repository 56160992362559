import React from 'react';
import { Link } from 'react-router-dom';

export function NotFound() {
  return (
    <>
      <h1>Not found</h1>
      <Link to={'/portfolio'}>See your portfolio</Link>
    </>
  );
}
