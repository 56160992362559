import { useCallback, useEffect } from 'react';
import { OnboardingHelper } from '../Helper/OnboardingHelper';
import { useDispatch, useSelector } from '@Hook/store';
import { setIsOnboardingVisible } from '@Slice/canvas';

export function useOnboarding() {
  const dispatch = useDispatch();

  const isVisible = useSelector((state) => state.canvas.showOnboarding);
  const setIsVisible = (value: boolean) => dispatch(setIsOnboardingVisible(value));

  const triggerOnboardingIfNecessary = () => {
    setTimeout(() => {
      if (OnboardingHelper.needsOnboarding()) {
        setIsVisible(true);
        OnboardingHelper.completeOnboarding();
      }
    }, 1000);
  };

  useEffect(triggerOnboardingIfNecessary, []);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === '?') {
      event.preventDefault();
      setIsVisible(true);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return { isVisible, setIsVisible };
}
