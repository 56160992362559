import { updateCanvasName } from '@Feature/Canvas/Api/CanvasRepository';
import React, { useState } from 'react';
import style from './CanvasName.module.css';
import { Input } from 'antd';

type FileNameProps = {
  canvasId: string;
  canvasName: string | null;
};

export const CanvasName = ({ canvasId, canvasName }: FileNameProps) => {
  const [title, setTitle] = useState(canvasName || 'Untitled');

  return (
    <span className={style.container}>
      <Input
        className={style.input}
        value={title}
        size={'small'}
        bordered={false}
        onChange={(event) => setTitle(event.target.value)}
        onClick={(event) => event.currentTarget.select()}
        onBlur={async (event) => {
          await updateCanvasName(canvasId, event.target.value);
        }}
        onPressEnter={async (event) => {
          await updateCanvasName(canvasId, event.currentTarget.value);
          event.currentTarget.blur();
        }}
      />
    </span>
  );
};
