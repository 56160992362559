import { isPrimaryModifierKey } from '@Utility/KeyboardEvent/keyboardEvent';
import { useCallback, useEffect } from 'react';

export function useExportShortcut(startExportExperience: () => void, cancelExportExperience: () => void) {
  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.target instanceof HTMLInputElement) {
      return;
    }

    if (event.code === 'KeyE' && isPrimaryModifierKey(event)) {
      startExportExperience();
    }

    if (event.code === 'Escape') {
      cancelExportExperience();
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
}
