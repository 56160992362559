import style from './ColorSwatch.module.css';
import { useDispatch } from '@Hook/store';
import { setActiveColor } from '@Slice/canvas';
import { Color } from '@Entity/Color';

type Props = {
  color: Color;
  active: boolean;
  alpha: number;
};

const ColorSwatch = ({ color, active, alpha }: Props) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    color.alpha = alpha;
    dispatch(setActiveColor(color.serialize()));
  };

  const activeClasses = active ? `${style.tile} ${style.active}` : style.tile;
  return <div className={activeClasses} style={{ backgroundColor: color.hex8String }} onClick={handleClick} />;
};

export { ColorSwatch };
